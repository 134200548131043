<template>
  <div>
    <AppBar />

    <v-row
      class="mb-6"
      justify="center"
      no-gutters>
      <h1 class="offBlack--text">UPDATE EMAIL ADDRESS</h1>
    </v-row>

    <v-form
      @submit.prevent="confirmChangeEmail"
      class="px-6">
      <v-row
        class="mb-4"
        no-gutters>
        <v-text-field
          color="primaryColor"
          type="email"
          v-model="email">
          <template slot="label"> Enter your <strong>work email address</strong> </template>
        </v-text-field>
      </v-row>

      <v-row
        justify="center"
        no-gutters>
        <v-col cols="10">
          <v-btn
            large
            type="submit"
            :loading="isChangingEmail"
            color="brand-gradient white--text disable--white"
            :disabled="isEmailDisabled"
            rounded
            elevation="0"
            block
            depressed
            >UPDATE EMAIL</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
  import ChangeEmailMixin from '@/views/profile/settings/change-email/ChangeEmail.mixin.vue';
  export default {
    mixins: [ChangeEmailMixin],
  };
</script>

<style scoped>
  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.16em;
  }
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    color: white !important;
  }
  .v-label.theme--light {
    color: var(--v-brandGrey2-base) !important;
    top: -10px !important;
  }
  .theme--light.v-label.v-label--active {
    margin-bottom: 40px;
  }
  .v-label {
    top: -10px !important;
  }
</style>
